import { isAdventure } from "../logic/adventures";
import { emptyOrNull, findCommonElements } from "../utils";
import { getData } from "./data";
import { useEffect, useState, createContext } from "react";

const data = getData();

/************************************************************
 * FUNCTIONS WITH NO STATE
 * Functions that don't access React state are found below.
 ************************************************************/

const getStoryById = (storyId) => data.stories.find((s) => s._id === storyId);

const storyWithCurrentSpecialEventId = (specialEvents, events) => {
  if (specialEvents) {
    const specialEventsRef = specialEvents.map((sE) => sE._ref);
    return findCommonElements(events, specialEventsRef);
  } else {
    return false;
  }
};

const storyHasSpecialEventId = (storyId, eventId) => {
  const story = getStoryById(storyId); // Recupera la storia specifica usando storyId
  if (!story) return false; // Se la storia non esiste, ritorna false

  // Controlla se la storia ha eventi e se uno di questi eventi ha _ref uguale a eventId
  return story.event?.some((e) => e._ref === eventId) || false;
};

const storyHasAdventureId = (story, adventureId) => {
  if (story.type !== "story") {
    return false;
  }

  return story.adventure.some((a) => a._ref === adventureId);
};

const getStoryIdFromTileId = (adventureId, tileId, events) => {
  const stories = data.stories.filter(
    (s) => storyHasAdventureId(s, adventureId) && s.tileId === parseInt(tileId)
  );

  const storyWithSpecialEvent = stories.find((s) =>
    storyWithCurrentSpecialEventId(s.event, events)
  );

  if (storyWithSpecialEvent) {
    return storyWithSpecialEvent?._id;
  } else {
    return stories.find((s) => emptyOrNull(s?.event))?._id;
  }
};

const getOneStoryFromTileId = (tileId) => {
  return data.stories.find((s) => s.tileId === parseInt(tileId));
};

/******** global functions for Stories */

const getAllStories = () => {
  var storyList = data.stories.filter((s) => s.type === "story");
  storyList.sort((a, b) => (a.tileId > b.tileId ? 1 : -1));
  return storyList;
};

const getStoryResultsByStoryRoot = (storyRootId) =>
  data.stories.filter(
    (s) => s.rootStory?._ref === storyRootId && s.type === "result"
  );

const getAllStoriesByAdventureId = (adventureId) => {
  var storyList = data.stories.filter(
    (s) => s.type === "story" && isAdventure(s.adventure, adventureId)
  );
  storyList.sort((a, b) => (a.tileId > b.tileId ? 1 : -1));
  return storyList;
};
const getAllStoryResultsByAdventureId = (adventureId) => {
  var storyResults = data.stories.filter(
    (s) =>
      s.type === "result" &&
      isAdventure(getStoryById(s.rootStory._ref).adventure, adventureId)
  );
  storyResults.sort((a, b) =>
    getStoryById(a.rootStory._ref).tileId >
    getStoryById(b.rootStory._ref).tileId
      ? 1
      : -1
  );
  return storyResults;
};

/************************************************************
 * REACT STATE
 * This module's React state is kept inside the context
 * defined below.
 ************************************************************/

/*
 * Custom context that holds the state for stories
 */
const StoriesContext = createContext({});

const StoriesContextProvider = ({ children }) => {
  const [currentStoryId, _setCurrentStoryId] = useState(null);
  const [storyHistory, _setStoryHistory] = useState([]);
  const [storiesCompleted, _setStoriesCompleted] = useState([]);

  // Recover saved values on start
  useEffect(() => {
    try {
      const savedCurrentStoryId = window.localStorage.getItem("currentStory");
      _setCurrentStoryId(savedCurrentStoryId);

      const savedStoryHistory = JSON.parse(
        window.localStorage.getItem("history")
      );
      _setStoryHistory(
        Array.isArray(savedStoryHistory) ? savedStoryHistory : []
      );

      const savedStoriesCompleted = JSON.parse(
        window.localStorage.getItem("storiesCompleted")
      );
      _setStoriesCompleted(
        Array.isArray(savedStoriesCompleted) ? savedStoriesCompleted : []
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  const setNextStory = (storyId) => {
    if (storyId == null) {
      console.error("STORY NOT FOUND");
      return;
    }
    const nextStory = getStoryById(storyId);

    if (nextStory.type === "story") {
      // Update state
      _setCurrentStoryId(nextStory._id);

      // Also update localStorage
      try {
        window.localStorage.setItem("currentStory", nextStory._id);
      } catch (error) {
        console.log(error);
      }
    }

    addToHistory(storyId);
  };

  const clearCurrentStory = () => {
    // Update state
    _setCurrentStoryId(null);

    // Also update localStorage
    try {
      window.localStorage.setItem("currentStory", null);
    } catch (error) {
      console.log(error);
    }
  };

  const setIntro = (adventureId, events) => {
    const intro = getStoryIdFromTileId(adventureId, 1, events);

    // Update state
    _setCurrentStoryId(intro);

    // Also update localStorage
    try {
      window.localStorage.setItem("currentStory", intro);
    } catch (error) {
      console.log(error);
    }
  };

  /******** functions for store History */
  const setHistory = (newHistory) => {
    // Update state
    _setStoryHistory(newHistory);

    // Also update localStorage
    try {
      window.localStorage.setItem("history", JSON.stringify(newHistory));
    } catch (error) {
      console.log(error);
    }
  };

  const addToHistory = (storyId) => {
    setHistory([...storyHistory, storyId]);
  };

  const clearHistory = () => {
    setHistory([]);
  };

  /******** functions for store Stories Completed */

  const setStoriesCompleted = (newStoriesCompleted) => {
    // Update state
    _setStoriesCompleted(newStoriesCompleted);

    // Also update localStorage
    try {
      window.localStorage.setItem(
        "storiesCompleted",
        JSON.stringify(newStoriesCompleted)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const addToStoriesCompleted = (storyId) => {
    setStoriesCompleted([...storiesCompleted, storyId]);
  };

  const addManyToStoriesCompleted = (storyIds) => {
    setStoriesCompleted([...storiesCompleted, ...storyIds]);
  };

  const clearStoryCompleted = () => {
    setStoriesCompleted([]);
  };

  const context = {
    setNextStory,
    clearCurrentStory,
    currentStoryId,
    setIntro,
    setHistory,
    storyHistory,
    addToHistory,
    clearHistory,
    setStoriesCompleted,
    storiesCompleted,
    addToStoriesCompleted,
    addManyToStoriesCompleted,
    clearStoryCompleted,
  };

  return (
    // the Provider gives access to the context to its children
    <StoriesContext.Provider value={context}>
      {children}
    </StoriesContext.Provider>
  );
};

const audioPlayer = (command) => {
  if (command == "play" && document.getElementById("audio")) {
    document.getElementById("audio").play();
  }

  if (command == "stop" && document.getElementById("audio")) {
    document.getElementById("audio").pause();
    document.getElementById("audio").currentTime = 0;
  }
};

export {
  getStoryById,
  storyWithCurrentSpecialEventId,
  storyHasSpecialEventId,
  storyHasAdventureId,
  getStoryIdFromTileId,
  getOneStoryFromTileId,
  getAllStories,
  getStoryResultsByStoryRoot,
  getAllStoriesByAdventureId,
  StoriesContext,
  StoriesContextProvider,
  getAllStoryResultsByAdventureId,
  audioPlayer,
};
